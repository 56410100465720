define("discourse/plugins/unit-staff-system/discourse/templates/uss/dashboard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h2>Dashboard</h2>
  <span>Work in progress. Come back later.</span>
  */
  {
    "id": "dTa9qVV8",
    "block": "[[[10,\"h2\"],[12],[1,\"Dashboard\"],[13],[1,\"\\n\"],[10,1],[12],[1,\"Work in progress. Come back later.\"],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/unit-staff-system/discourse/templates/uss/dashboard.hbs",
    "isStrictMode": false
  });
});