define("discourse/plugins/unit-staff-system/discourse/templates/components/title-editor", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="uss-title-editor">
      {{#unless this.editing}}
          <h1 class="uss-title">
              {{this.title}}
              <a {{on "click" this.startEditTitle}}>
                  {{d-icon "pencil-alt" class="uss-title-edit-icon"}}
              </a>
          </h1>
      {{else}}
          <TextField @value={{this.draftTitle}} class="uss-title-edit"/>
          <DButton @class="ok" @action={{action "confirmEditTitle"}} @icon="check" />
          <DButton @class="cancel" @action={{action "cancelEditTitle"}} @icon="times" />
      {{/unless}}
  </div>
  
  */
  {
    "id": "TawVoSWV",
    "block": "[[[10,0],[14,0,\"uss-title-editor\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"editing\"]]],[[[1,\"        \"],[10,\"h1\"],[14,0,\"uss-title\"],[12],[1,\"\\n            \"],[1,[30,0,[\"title\"]]],[1,\"\\n            \"],[11,3],[4,[38,1],[\"click\",[30,0,[\"startEditTitle\"]]],null],[12],[1,\"\\n                \"],[1,[28,[35,2],[\"pencil-alt\"],[[\"class\"],[\"uss-title-edit-icon\"]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,3],[[24,0,\"uss-title-edit\"]],[[\"@value\"],[[30,0,[\"draftTitle\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@class\",\"@action\",\"@icon\"],[\"ok\",[28,[37,5],[[30,0],\"confirmEditTitle\"],null],\"check\"]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@class\",\"@action\",\"@icon\"],[\"cancel\",[28,[37,5],[[30,0],\"cancelEditTitle\"],null],\"times\"]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"unless\",\"on\",\"d-icon\",\"text-field\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/unit-staff-system/discourse/templates/components/title-editor.hbs",
    "isStrictMode": false
  });
});