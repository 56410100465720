define("discourse/plugins/unit-staff-system/discourse/uss-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("uss", {
      resetNamespace: true
    }, function () {
      this.route("dashboard", {
        path: "/"
      });
      this.route("attendance", {
        path: "/attendance"
      });
      this.route("roster", {
        path: "/roster"
      });
    });
  }
});