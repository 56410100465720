define("discourse/plugins/unit-staff-system/discourse/components/platoon-editor", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service", "I18n"], function (_exports, _component, _tracking, _object, _ajax, _ajaxError, _service, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PlatoonEditor extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    /*
    args:
    - platoon
    - onChange(squad)
    - onDelete(squad)
    - onExit()
     */
    static #_2 = (() => dt7948.g(this.prototype, "platoon", [_tracking.tracked]))();
    #platoon = (() => (dt7948.i(this, "platoon"), void 0))();
    // Create squad properties
    createSquadName = "";
    createSquadDisabled = false;
    constructor() {
      super(...arguments);
      this.platoon = this.args.platoon;
      this.squad = this.args.squad;
    }
    get selectableSquadTypes() {
      return [{
        name: _I18n.default.t("unit_staff_system.admin.squads.types.training"),
        value: true
      }, {
        name: _I18n.default.t("unit_staff_system.admin.squads.types.permanent"),
        value: false
      }];
    }
    get selectableSquadReserves() {
      return [{
        name: _I18n.default.t("unit_staff_system.admin.squads.reserves.true"),
        value: true
      }, {
        name: _I18n.default.t("unit_staff_system.admin.squads.reserves.false"),
        value: false
      }];
    }
    updatePlatoon(platoon) {
      this.platoon = platoon;
      this.args.onChange(platoon);
    }
    updatePlatoonRemote(requestType, urlAction) {
      (0, _ajax.ajax)(`/uss/roster/platoons/${this.platoon.id}${urlAction}`, {
        type: requestType
      }).then(platoon => {
        this.updatePlatoon(platoon);
      }).catch(err => {
        (0, _ajaxError.popupAjaxError)(err);
        throw err;
      });
    }
    onExit() {
      this.args.onExit();
    }
    static #_3 = (() => dt7948.n(this.prototype, "onExit", [_object.action]))();
    onCreateSquad() {
      const name = this.createSquadName.trim();
      this.set('createSquadName', '');
      this.set('createSquadDisabled', true);
      this.updatePlatoonRemote("POST", `/squads?${new URLSearchParams({
        name
      })}`);
      (0, _ajax.ajax)(`/uss/roster/platoons/${this.platoon_id}/squads?${new URLSearchParams({
        name
      })}`, {
        type: "POST"
      }).then(newSquad => {
        this.platoon.squads.pushObject(newSquad);
        this.updatePlatoon(this.platoon);
      }).catch(err => {
        (0, _ajaxError.popupAjaxError)(err);
        throw err;
      }).finally(() => {
        this.set('createSquadDisabled', false);
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "onCreateSquad", [_object.action]))();
    onChangePlatoonName(name) {
      this.updatePlatoonRemote("PUT", `?${new URLSearchParams({
        name
      })}`);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChangePlatoonName", [_object.action]))();
    onChangePlatoonLeader(username) {
      this.updatePlatoonRemote("PUT", `?${new URLSearchParams({
        leader_username: username
      })}`);
    }
    static #_6 = (() => dt7948.n(this.prototype, "onChangePlatoonLeader", [_object.action]))();
    onChangePlatoonAssistant(username) {
      this.updatePlatoonRemote("PUT", `?${new URLSearchParams({
        assistant_username: username
      })}`);
    }
    static #_7 = (() => dt7948.n(this.prototype, "onChangePlatoonAssistant", [_object.action]))();
    onDeletePlatoon() {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("unit_staff_system.admin.platoons.editor.delete.confirmation.title"),
        didConfirm: () => {
          (0, _ajax.ajax)("/uss/roster/platoons/" + this.platoon.id, {
            type: "DELETE"
          }).then(() => {
            this.args.onDelete(this.platoon);
          }).catch(err => {
            (0, _ajaxError.popupAjaxError)(err);
            throw err;
          });
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "onDeletePlatoon", [_object.action]))();
  }
  _exports.default = PlatoonEditor;
  ;
});