define("discourse/plugins/unit-staff-system/discourse/controllers/uss/attendance", ["exports", "@ember/controller", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "@ember/service", "discourse/config/environment"], function (_exports, _controller, _ajax, _ajaxError, _decorators, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    selectedSquadId: null,
    selectedSquadAttendances: null,
    selectedSquadAttendancesDirty: false,
    viewingAttendance: false,
    editingAttendance: false,
    draftingAttendance: false,
    draftingMissionBriefing: false,
    editingDisabled: true,
    isAboveSquadStaff: false,
    selectedAttendanceId: null,
    members: null,
    attendanceDate: null,
    attendanceTime: null,
    attendanceType: true,
    reportInputs: {
      reality: null,
      wentWell: null,
      notWell: null,
      toBeChanged: null
    },
    missionBriefingText: null,
    selectedSquad() {
      let selectedSquadIndex = this.model.squads.findIndex(squad => {
        return squad.id == this.selectedSquadId;
      });
      if (selectedSquadIndex < 0) {
        return null;
      }
      return this.model.squads[selectedSquadIndex];
    },
    selectableAttendanceTypes() {
      return [{
        name: "Mandatory",
        value: true
      }, {
        name: "Optional",
        value: false
      }];
    },
    selectableAttendanceTimes() {
      return [{
        name: "12:00 AM",
        value: "00:00"
      }, {
        name: "1:00 AM",
        value: "01:00"
      }, {
        name: "2:00 AM",
        value: "02:00"
      }, {
        name: "3:00 AM",
        value: "03:00"
      }, {
        name: "4:00 AM",
        value: "04:00"
      }, {
        name: "5:00 AM",
        value: "05:00"
      }, {
        name: "6:00 AM",
        value: "06:00"
      }, {
        name: "7:00 AM",
        value: "07:00"
      }, {
        name: "8:00 AM",
        value: "08:00"
      }, {
        name: "9:00 AM",
        value: "09:00"
      }, {
        name: "10:00 AM",
        value: "10:00"
      }, {
        name: "11:00 AM",
        value: "11:00"
      }, {
        name: "12:00 PM",
        value: "12:00"
      }, {
        name: "1:00 PM",
        value: "13:00"
      }, {
        name: "2:00 PM",
        value: "14:00"
      }, {
        name: "3:00 PM",
        value: "15:00"
      }, {
        name: "4:00 PM",
        value: "16:00"
      }, {
        name: "5:00 PM",
        value: "17:00"
      }, {
        name: "6:00 PM",
        value: "18:00"
      }, {
        name: "7:00 PM",
        value: "19:00"
      }, {
        name: "8:00 PM",
        value: "20:00"
      }, {
        name: "9:00 PM",
        value: "21:00"
      }, {
        name: "10:00 PM",
        value: "22:00"
      }, {
        name: "11:00 PM",
        value: "23:00"
      }];
    },
    isAWOLDisabled() {
      return this.editingDisabled || !this.attendanceType;
    },
    actions: {
      onSquadSelected(squadId) {
        this.set('selectedSquadId', squadId);
        this.send("refreshSquadAttendance");
      },
      resetAttendanceView() {
        this.setProperties({
          viewingAttendance: false,
          draftingAttendance: false,
          draftingMissionBriefing: false,
          editingAttendance: false,
          editingDisabled: false,
          selectedAttendanceId: null,
          members: null,
          attendanceDate: null,
          attendanceTime: null,
          attendanceType: true,
          missionBriefingText: null
        });
      },
      refreshSquadAttendance() {
        this.setProperties({
          selectedSquadAttendances: null,
          selectedSquadAttendancesDirty: false,
          isAboveSquadStaff: _environment.default.ussProfile.rank > 5
        });
        this.send('resetAttendanceView');
        (0, _ajax.ajax)(`/uss/attendance/${this.selectedSquadId}`, {
          type: "GET"
        }).then(squadAttendances => {
          this.set('selectedSquadAttendances', squadAttendances.attendances || []);
        }).catch(err => {
          (0, _ajaxError.popupAjaxError)(err);
          throw err;
        });
      },
      viewAttendance(attendanceId) {
        (0, _ajax.ajax)(`/uss/attendance/${this.selectedSquadId}/${attendanceId}`, {
          type: "GET"
        }).then(attendance => {
          let members = [];
          function addUser(user) {
            if (user == null) {
              return;
            }
            members.push({
              username: user.user.username,
              presence: user.presence.toString()
            });
          }
          attendance.attendees.forEach(addUser);
          this.setProperties({
            viewingAttendance: true,
            draftingAttendance: false,
            draftingMissionBriefing: false,
            editingAttendance: false,
            editingDisabled: true,
            selectedAttendanceId: attendance.id,
            members: members,
            attendanceDate: attendance.practice_datetime.slice(0, 10),
            attendanceTime: attendance.practice_datetime.slice(11, 16),
            attendanceType: attendance.is_mandatory,
            missionBriefingText: null
          });
        }).catch(err => {
          (0, _ajaxError.popupAjaxError)(err);
          throw err;
        });
      },
      draftAttendance() {
        let members = [];
        let squad = this.selectedSquad;
        function addUser(user) {
          if (user == null) {
            return;
          }
          const defaultPresence = '1';
          members.push({
            username: user.username,
            presence: defaultPresence
          });
        }
        addUser(squad.leader_user);
        addUser(squad.assistant_user);
        squad.users.forEach(addUser);
        this.setProperties({
          viewingAttendance: true,
          draftingAttendance: true,
          draftingMissionBriefing: false,
          editingAttendance: false,
          editingDisabled: false,
          selectedAttendanceId: null,
          members: members,
          attendanceDate: new Date().toISOString().slice(0, 10),
          attendanceTime: "20:00",
          attendanceType: true,
          missionBriefingText: null
        });
      },
      draftMissionBriefing() {
        let members = [];
        let squad = this.selectedSquad;
        function addUser(user) {
          if (user == null) {
            return;
          }
          const defaultPresence = '1';
          members.push({
            username: user.username,
            presence: defaultPresence
          });
        }
        addUser(squad.leader_user);
        addUser(squad.assistant_user);
        squad.users.forEach(addUser);
        this.setProperties({
          viewingAttendance: true,
          draftingAttendance: true,
          draftingMissionBriefing: true,
          editingAttendance: false,
          editingDisabled: false,
          selectedAttendanceId: null,
          members: members,
          attendanceDate: new Date().toISOString().slice(0, 10),
          attendanceTime: "20:00",
          attendanceType: true,
          missionBriefingText: null
        });
      },
      editAttendance() {
        this.setProperties({
          editingAttendance: true,
          editingDisabled: false
        });
      },
      cancelEditAttendance() {
        this.send("viewAttendance", this.selectedAttendanceId);
      },
      submitAttendance() {
        if (this.editingAttendance) {
          (0, _ajax.ajax)(`/uss/attendance/${this.selectedSquadId}/${this.selectedAttendanceId}`, {
            type: "PUT",
            data: JSON.stringify({
              datetime: `${this.attendanceDate}T${this.attendanceTime}Z`,
              is_mandatory: this.attendanceType,
              users: this.members.map(function (member) {
                return {
                  username: member.username,
                  presence: parseInt(member.presence, 10)
                };
              })
            }),
            contentType: "application/json"
          }).then(attendance => {
            this.set("selectedSquadAttendancesDirty", true);
            this.send("viewAttendance", attendance.id);
          }).catch(err => {
            (0, _ajaxError.popupAjaxError)(err);
            throw err;
          });
        } else {
          (0, _ajax.ajax)(`/uss/attendance/${this.selectedSquadId}`, {
            type: "POST",
            data: JSON.stringify({
              datetime: `${this.attendanceDate}T${this.attendanceTime}Z`,
              is_mandatory: this.attendanceType,
              users: this.members.map(function (member) {
                return {
                  username: member.username,
                  presence: parseInt(member.presence, 10)
                };
              })
            }),
            contentType: "application/json"
          }).then(attendance => {
            this.set("selectedSquadAttendancesDirty", true);
            this.send("viewAttendance", attendance.id);
          }).catch(err => {
            (0, _ajaxError.popupAjaxError)(err);
            throw err;
          });
        }
      },
      cancelAttendance() {
        if (this.selectedSquadAttendancesDirty) {
          this.send('refreshSquadAttendance');
        } else {
          this.send('resetAttendanceView');
        }
      },
      deleteAttendance() {
        this.dialog.deleteConfirm({
          message: I18n.t("unit_staff_system.attendance.delete.confirmation.title"),
          didConfirm: () => {
            (0, _ajax.ajax)(`/uss/attendance/${this.selectedSquadId}/${this.selectedAttendanceId}`, {
              type: "DELETE"
            }).then(() => {
              this.send("refreshSquadAttendance");
            }).catch(err => {
              (0, _ajaxError.popupAjaxError)(err);
              throw err;
            });
          }
        });
      }
    }
  }, [["method", "selectedSquad", [(0, _decorators.default)('selectedSquadId')]], ["method", "selectableAttendanceTypes", [_decorators.default]], ["method", "selectableAttendanceTimes", [_decorators.default]], ["method", "isAWOLDisabled", [(0, _decorators.default)('editingDisabled', 'attendanceType')]]]));
});