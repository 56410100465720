define("discourse/plugins/unit-staff-system/discourse/templates/uss", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="uss-container">
    <h1>
      {{i18n "unit_staff_system.title"}}
    </h1>
    <div class="uss-main-nav">
      <ul class="nav nav-pills">
        <NavItem @route="uss.dashboard" @label="unit_staff_system.dashboard.title" />
        <NavItem @route="uss.attendance" @label="unit_staff_system.attendance.title" />
        {{#if @model.above_ss}}
          <NavItem @route="uss.roster" @label="unit_staff_system.roster_admin.title" />
        {{/if}}
      </ul>
    </div>
  
    <div class="uss-content">
      {{outlet}}
    </div>
  </div>
  
  */
  {
    "id": "rwSQiJeR",
    "block": "[[[10,0],[14,0,\"uss-container\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"unit_staff_system.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"uss-main-nav\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"uss.dashboard\",\"unit_staff_system.dashboard.title\"]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"uss.attendance\",\"unit_staff_system.attendance.title\"]],null],[1,\"\\n\"],[41,[30,1,[\"above_ss\"]],[[[1,\"        \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"uss.roster\",\"unit_staff_system.roster_admin.title\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"uss-content\"],[12],[1,\"\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\"],false,[\"i18n\",\"nav-item\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/unit-staff-system/discourse/templates/uss.hbs",
    "isStrictMode": false
  });
});