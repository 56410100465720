define("discourse/plugins/unit-staff-system/discourse/components/squad-editor", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service", "I18n"], function (_exports, _component, _tracking, _object, _ajax, _ajaxError, _service, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SquadEditor extends _component.default {
    /*
    args:
    - platoon
    - squad
    - onChange(squad)
    - onDelete(squad)
    - onExit()
     */

    platoon;
    static #_ = (() => dt7948.g(this.prototype, "squad", [_tracking.tracked]))();
    #squad = (() => (dt7948.i(this, "squad"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    constructor() {
      super(...arguments);
      this.platoon = this.args.platoon;
      this.squad = this.args.squad;
    }
    get selectableSquadTypes() {
      return [{
        name: _I18n.default.t("unit_staff_system.admin.squads.types.training"),
        value: true
      }, {
        name: _I18n.default.t("unit_staff_system.admin.squads.types.permanent"),
        value: false
      }];
    }
    get selectableSquadReserves() {
      return [{
        name: _I18n.default.t("unit_staff_system.admin.squads.reserves.true"),
        value: true
      }, {
        name: _I18n.default.t("unit_staff_system.admin.squads.reserves.false"),
        value: false
      }];
    }
    updateSquad(squad) {
      this.squad = squad;
      this.args.onChange(squad);
    }
    updateSquadRemote(requestType, urlAction) {
      (0, _ajax.ajax)(`/uss/roster/platoons/${this.platoon.id}/squads/${this.squad.id}${urlAction}`, {
        type: requestType
      }).then(squad => {
        this.updateSquad(squad);
      }).catch(err => {
        (0, _ajaxError.popupAjaxError)(err);
        throw err;
      });
    }
    onExit() {
      this.args.onExit();
    }
    static #_3 = (() => dt7948.n(this.prototype, "onExit", [_object.action]))();
    onChangeSquadName(name) {
      this.updateSquadRemote("PUT", `?${new URLSearchParams({
        name
      })}`);
    }
    static #_4 = (() => dt7948.n(this.prototype, "onChangeSquadName", [_object.action]))();
    onChangeSquadLeader(username) {
      this.updateSquadRemote("PUT", `?${new URLSearchParams({
        leader_username: username
      })}`);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChangeSquadLeader", [_object.action]))();
    onChangeSquadAssistant(username) {
      this.updateSquadRemote("PUT", `?${new URLSearchParams({
        assistant_username: username
      })}`);
    }
    static #_6 = (() => dt7948.n(this.prototype, "onChangeSquadAssistant", [_object.action]))();
    onChangeSquadType(type) {
      this.updateSquadRemote("PUT", `?${new URLSearchParams({
        is_training: type
      })}`);
    }
    static #_7 = (() => dt7948.n(this.prototype, "onChangeSquadType", [_object.action]))();
    onChangeSquadReserves(reserves) {
      this.updateSquadRemote("PUT", `?${new URLSearchParams({
        is_reserves: reserves
      })}`);
    }
    static #_8 = (() => dt7948.n(this.prototype, "onChangeSquadReserves", [_object.action]))();
    onChangeSquadOrder(order) {
      this.updateSquadRemote("PUT", `?${new URLSearchParams({
        order
      })}`);
    }
    static #_9 = (() => dt7948.n(this.prototype, "onChangeSquadOrder", [_object.action]))();
    onChangeSquadTopic(topic) {
      this.updateSquadRemote("PUT", `?${new URLSearchParams({
        topic_id: topic
      })}`);
    }
    static #_10 = (() => dt7948.n(this.prototype, "onChangeSquadTopic", [_object.action]))();
    onChangeSquadTimezone(timezone) {
      this.updateSquadRemote("PUT", `?${new URLSearchParams({
        timezone
      })}`);
    }
    static #_11 = (() => dt7948.n(this.prototype, "onChangeSquadTimezone", [_object.action]))();
    onAddSquadMember(username) {
      this.updateSquadRemote("POST", `/members/${username}`);
    }
    static #_12 = (() => dt7948.n(this.prototype, "onAddSquadMember", [_object.action]))();
    onRemoveSquadMember(username) {
      this.updateSquadRemote("DELETE", `/members/${username}`);
    }
    static #_13 = (() => dt7948.n(this.prototype, "onRemoveSquadMember", [_object.action]))();
    onDeleteSquad() {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("unit_staff_system.admin.squads.editor.delete.confirmation.title"),
        didConfirm: () => {
          (0, _ajax.ajax)(`/uss/roster/platoons/${this.platoon.id}/squads/${this.squad.id}`, {
            type: "DELETE"
          }).then(() => {
            this.args.onDelete(this.squad);
          }).catch(err => {
            (0, _ajaxError.popupAjaxError)(err);
            throw err;
          });
        }
      });
    }
    static #_14 = (() => dt7948.n(this.prototype, "onDeleteSquad", [_object.action]))();
  }
  _exports.default = SquadEditor;
  ;
});