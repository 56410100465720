define("discourse/plugins/unit-staff-system/discourse/initializers/initialize-unit-staff-system", ["exports", "discourse/config/environment", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _environment, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "initialize-unit-staff-system",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.20", api => {
        if (Discourse.User.current()) {
          (0, _ajax.ajax)("/uss/profile", {
            type: "GET"
          }).then(profile => {
            _environment.default.ussProfile = profile;
          }).catch(err => {
            throw err;
          });
        } else {
          _environment.default.ussProfile = {
            rank: 0,
            platoon: null,
            squad: null
          };
        }
      });
    }
  };
});