define("discourse/plugins/unit-staff-system/discourse/components/property-editor-user", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PropertyEditorUser extends _component.default {
    constructor() {
      super(...arguments);
    }
    onChangeUsers(usernames) {
      let newValue = usernames.slice(-1);
      if (newValue == this.args.value) {
        return;
      }
      this.args.onChange(newValue);
    }
    static #_ = (() => dt7948.n(this.prototype, "onChangeUsers", [_object.action]))();
  }
  _exports.default = PropertyEditorUser;
  ;
});