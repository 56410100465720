define("discourse/plugins/unit-staff-system/discourse/routes/uss/attendance", ["exports", "discourse/routes/discourse", "@ember/object", "discourse/models/composer", "discourse/models/topic", "discourse/models/topic-details"], function (_exports, _discourse, _object, _composer, _topic, _topicDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(dt7948.p({
    async model(params) {
      function compareSquads(a, b) {
        if (a.platoon_id < b.platoon_id) {
          return -1;
        }
        if (a.platoon_id > b.platoon_id) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      }
      let response = await fetch('/uss/roster/managing/squads.json');
      let squads = await response.json();
      squads = squads.filter(squad => !squad.is_reserves);
      squads.sort(compareSquads);
      if (squads.length == 1) {
        let controller = this.controllerFor('uss.attendance');
        controller.send('onSquadSelected', squads[0].id);
      }
      return {
        squads
      };
    },
    async draftBriefing() {
      let controller = this.controllerFor('uss.attendance');
      let props = controller.getProperties(["selectedSquad", "attendanceDate", "attendanceTime", "attendanceType", "selectableAttendanceTimes", "selectableAttendanceTypes", "members", "missionBriefingText"]);
      let squad = props.selectedSquad;
      let topicResponse = await fetch(`/t/${squad.topic_id}.json`);
      let topic = await topicResponse.json();
      if (topic.details) {
        topic.details = _topicDetails.default.create(topic.details);
      }
      let record = _topic.default.create(topic);
      let members = props.members;
      let attendanceDate = props.attendanceDate;
      let attendanceTime = props.attendanceTime;
      let attendanceType = props.attendanceType;
      let selectableAttendanceTimes = props.selectableAttendanceTimes;
      let selectableAttendanceTypes = props.selectableAttendanceTypes;
      function listMembers(list) {
        return list.map(member => "* @" + member.username).join("\n");
      }
      let text = I18n.t("unit_staff_system.attendance.briefing.template", {
        date: attendanceDate.replaceAll('-', '/'),
        time: selectableAttendanceTimes.filter(x => x.value == attendanceTime)[0].name,
        timezone: squad.timezone,
        type: selectableAttendanceTypes.filter(x => x.value == attendanceType)[0].name,
        membersExpected: listMembers(members.filter(member => member.presence == 1)),
        membersExcused: listMembers(members.filter(member => member.presence == 0)),
        missionBriefingText: props.missionBriefingText || ""
      });
      let draftKey = topic.draft_key;
      this.controllerFor("composer").open({
        action: _composer.default.REPLY,
        draftKey: draftKey,
        topic: record,
        quote: text
      });
      controller.send("refreshSquadAttendance");
    },
    async draftReport() {
      let controller = this.controllerFor('uss.attendance');
      let props = controller.getProperties(["selectedSquad", "attendanceDate", "attendanceTime", "attendanceType", "selectableAttendanceTimes", "selectableAttendanceTypes", "reportInputs", "members"]);
      let squad = props.selectedSquad;
      let topicResponse = await fetch(`/t/${squad.topic_id}.json`);
      let topic = await topicResponse.json();
      if (topic.details) {
        topic.details = _topicDetails.default.create(topic.details);
      }
      let record = _topic.default.create(topic);
      let members = props.members;
      let attendanceDate = props.attendanceDate;
      let attendanceTime = props.attendanceTime;
      let attendanceType = props.attendanceType;
      let selectableAttendanceTimes = props.selectableAttendanceTimes;
      let selectableAttendanceTypes = props.selectableAttendanceTypes;
      let reportInputs = props.reportInputs;
      function listMembers(list) {
        return list.map(member => "* @" + member.username).join("\n");
      }
      let text = I18n.t("unit_staff_system.attendance.report.template", {
        date: attendanceDate.replaceAll('-', '/'),
        time: selectableAttendanceTimes.filter(x => x.value == attendanceTime)[0].name,
        timezone: squad.timezone,
        type: selectableAttendanceTypes.filter(x => x.value == attendanceType)[0].name,
        membersPresent: listMembers(members.filter(member => member.presence == 1)),
        membersExcused: listMembers(members.filter(member => member.presence == 0)),
        membersAWOL: listMembers(members.filter(member => member.presence == 2)),
        reality: reportInputs.reality || "",
        wentWell: reportInputs.wentWell || "",
        notWell: reportInputs.notWell || "",
        toBeChanged: reportInputs.toBeChanged || ""
      });
      let draftKey = topic.draft_key;
      this.controllerFor("composer").open({
        action: _composer.default.REPLY,
        draftKey: draftKey,
        topic: record,
        quote: text
      });
      controller.send("refreshSquadAttendance");
    }
  }, [["method", "draftBriefing", [_object.action]], ["method", "draftReport", [_object.action]]]));
});