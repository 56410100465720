define("discourse/plugins/unit-staff-system/discourse/routes/uss/roster", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    async model(params) {
      function compare(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      let response = await fetch('/uss/roster.json');
      let roster = await response.json();
      roster.platoons.sort(compare);
      roster.company_staff = {
        chief_of_staff: null,
        vice_chief_of_staff: null
      };
      for (let companyMember of roster.company_members) {
        switch (companyMember.position) {
          case 1:
            roster.company_staff.chief_of_staff = companyMember.user.username;
            break;
          case 2:
            roster.company_staff.vice_chief_of_staff = companyMember.user.username;
            break;
        }
      }
      return roster;
    }
  });
});