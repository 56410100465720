define("discourse/plugins/unit-staff-system/discourse/components/property-editor-options", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PropertyEditorOptions extends _component.default {
    constructor() {
      super(...arguments);
    }
    onChangeOption(option) {
      if (option == this.args.value) {
        return;
      }
      this.args.onChange(option);
    }
    static #_ = (() => dt7948.n(this.prototype, "onChangeOption", [_object.action]))();
  }
  _exports.default = PropertyEditorOptions;
  ;
});