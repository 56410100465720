define("discourse/plugins/unit-staff-system/discourse/templates/components/property-editor-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="uss-property-label">{{i18n @label}}</label>
  <TextField
    class="uss-property-field"
    @value={{this.value}}
    @onChange={{action "onChangeText"}}
  />
  
  */
  {
    "id": "IEuxKl0O",
    "block": "[[[10,\"label\"],[14,0,\"uss-property-label\"],[12],[1,[28,[35,0],[[30,1]],null]],[13],[1,\"\\n\"],[8,[39,1],[[24,0,\"uss-property-field\"]],[[\"@value\",\"@onChange\"],[[30,0,[\"value\"]],[28,[37,2],[[30,0],\"onChangeText\"],null]]],null],[1,\"\\n\"]],[\"@label\"],false,[\"i18n\",\"text-field\",\"action\"]]",
    "moduleName": "discourse/plugins/unit-staff-system/discourse/templates/components/property-editor-text.hbs",
    "isStrictMode": false
  });
});