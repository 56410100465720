define("discourse/plugins/unit-staff-system/discourse/components/property-editor-text", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PropertyEditorText extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "value", [_tracking.tracked]))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    prevValue;
    constructor() {
      super(...arguments);
      this.value = this.args.value;
      this.prevValue = this.value;
    }
    onChangeText(text) {
      if (text == this.prevValue) {
        return;
      }
      this.prevValue = text;
      this.value = text;
      this.args.onChange(text);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onChangeText", [_object.action]))();
  }
  _exports.default = PropertyEditorText;
  ;
});