define("discourse/plugins/unit-staff-system/discourse/components/property-editor-number", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n"], function (_exports, _component, _tracking, _object, _service, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PropertyEditorNumber extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "value", [_tracking.tracked]))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    prevValue;
    constructor() {
      super(...arguments);
      this.value = this.args.value;
      this.prevValue = this.value;
    }
    onChangeText(text) {
      let number = parseInt(text, 10);
      if (isNaN(number)) {
        this.dialog.alert({
          message: _I18n.default.t("unit_staff_system.common.errors.invalid_number")
        });
        return;
      }
      if (number == this.prevValue) {
        return;
      }
      this.prevValue = number;
      this.value = number;
      this.args.onChange(number);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeText", [_object.action]))();
  }
  _exports.default = PropertyEditorNumber;
  ;
});