define("discourse/plugins/unit-staff-system/discourse/controllers/uss/roster", ["exports", "@ember/controller", "I18n", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _ajax, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    queryParams: ['platoon_id', 'squad_id'],
    platoon_id: -1,
    squad_id: -1,
    selectedPlatoon() {
      let selectedIndex = this.model.platoons.findIndex(platoon => {
        return platoon.id == this.platoon_id;
      });
      if (selectedIndex < 0) {
        return null;
      }
      return this.model.platoons[selectedIndex];
    },
    selectedSquad() {
      let selectedPlatoon = null;
      let selectedPlatoonIndex = this.model.platoons.findIndex(platoon => {
        return platoon.id == this.platoon_id;
      });
      if (selectedPlatoonIndex < 0) {
        return null;
      }
      selectedPlatoon = this.model.platoons[selectedPlatoonIndex];
      if (selectedPlatoon == null) {
        return null;
      }
      let selectedSquadIndex = selectedPlatoon.squads.findIndex(squad => {
        return squad.id == this.squad_id;
      });
      if (selectedSquadIndex < 0) {
        return null;
      }
      return selectedPlatoon.squads[selectedSquadIndex];
    },
    selectableSquadTypes() {
      return [{
        name: _I18n.default.t("unit_staff_system.admin.squads.types.training"),
        value: true
      }, {
        name: _I18n.default.t("unit_staff_system.admin.squads.types.permanent"),
        value: false
      }];
    },
    selectableSquadReserves() {
      return [{
        name: _I18n.default.t("unit_staff_system.admin.squads.reserves.true"),
        value: true
      }, {
        name: _I18n.default.t("unit_staff_system.admin.squads.reserves.false"),
        value: false
      }];
    },
    actions: {
      onChangeChiefOfStaff(username) {
        let path, type;
        if (username.length > 0) {
          path = `/uss/roster/company/${username}/1`;
          type = 'POST';
        } else {
          username = this.model.company_staff.chief_of_staff;
          path = `/uss/roster/company/${username}`;
          type = 'DELETE';
        }
        (0, _ajax.ajax)(path, {
          type
        }).then(company_members => {
          let newUsername = null;
          for (let companyMember of company_members) {
            if (companyMember.position == 1) {
              newUsername = companyMember.user.username;
              break;
            }
          }
          this.set('model.company_staff.chief_of_staff', newUsername);
        }).catch(err => {
          (0, _ajaxError.popupAjaxError)(err);
          throw err;
        });
      },
      onChangeViceChiefOfStaff(username) {
        let path, type;
        if (username.length > 0) {
          path = `/uss/roster/company/${username}/2`;
          type = 'POST';
        } else {
          username = this.model.company_staff.vice_chief_of_staff;
          path = `/uss/roster/company/${username}`;
          type = 'DELETE';
        }
        (0, _ajax.ajax)(path, {
          type
        }).then(company_members => {
          let newUsername = null;
          for (let companyMember of company_members) {
            if (companyMember.position == 2) {
              newUsername = companyMember.user.username;
              break;
            }
          }
          this.set('model.company_staff.vice_chief_of_staff', newUsername);
        }).catch(err => {
          (0, _ajaxError.popupAjaxError)(err);
          throw err;
        });
      },
      createPlatoon() {
        const name = this.createPlatoonName.trim();
        this.set('createPlatoonName', '');
        this.set('createPlatoonDisabled', true);
        (0, _ajax.ajax)("/uss/roster/platoons?" + new URLSearchParams({
          name
        }), {
          type: "POST"
        }).then(newPlatoon => {
          this.model.platoons.pushObject(newPlatoon);
        }).catch(err => {
          (0, _ajaxError.popupAjaxError)(err);
          throw err;
        }).finally(() => {
          this.set('createPlatoonDisabled', false);
        });
      },
      onDeselectPlatoon() {
        this.setProperties({
          platoon_id: -1,
          squad_id: -1
        });
      },
      onChangePlatoon(platoon) {
        let platoonIndex = this.model.platoons.findIndex(platoon => {
          return platoon.id == this.platoon_id;
        });
        if (platoonIndex < 0) {
          return;
        }
        this.model.platoons[platoonIndex] = platoon;
        if (this.platoon_id == platoon.id) {
          this.set('platoon_id', platoon.id);
        }
      },
      onDeletePlatoon(platoon) {
        this.setProperties({
          platoon_id: -1,
          squad_id: -1
        });
        this.model.platoons.removeObject(platoon);
      },
      onDeselectSquad() {
        this.set('squad_id', -1);
      },
      onChangeSquad(squad) {
        let squadIndex = this.selectedPlatoon.squads.findIndex(s => {
          return s.id == squad.id;
        });
        if (squadIndex < 0) {
          return;
        }
        this.selectedPlatoon.squads[squadIndex] = squad;
        if (this.squad_id == squad.id) {
          this.set('squad_id', squad.id);
        }
      },
      onDeleteSquad(squad) {
        this.set('squad_id', -1);
        this.selectedPlatoon.squads.removeObject(squad);
      }
    }
  }, [["method", "selectedPlatoon", [(0, _decorators.default)('platoon_id')]], ["method", "selectedSquad", [(0, _decorators.default)('platoon_id', 'squad_id')]], ["method", "selectableSquadTypes", [_decorators.default]], ["method", "selectableSquadReserves", [_decorators.default]]]));
});