define("discourse/plugins/unit-staff-system/discourse/components/title-editor", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TitleEditor extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "editing", [_tracking.tracked]))();
    #editing = (() => (dt7948.i(this, "editing"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "title", [_tracking.tracked]))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "draftTitle", [_tracking.tracked]))();
    #draftTitle = (() => (dt7948.i(this, "draftTitle"), void 0))();
    constructor() {
      super(...arguments);
      this.editing = false;
      this.title = this.args.title;
      this.draftTitle = null;
    }
    startEditTitle() {
      this.editing = true;
      this.draftTitle = this.title;
    }
    static #_4 = (() => dt7948.n(this.prototype, "startEditTitle", [_object.action]))();
    cancelEditTitle() {
      this.editing = false;
      this.draftTitle = null;
    }
    static #_5 = (() => dt7948.n(this.prototype, "cancelEditTitle", [_object.action]))();
    confirmEditTitle() {
      let hasChanged = this.draftTitle != this.title;
      this.title = this.draftTitle;
      this.draftTitle = null;
      if (hasChanged) {
        this.args.onChange(this.title);
      }
      this.editing = false;
    }
    static #_6 = (() => dt7948.n(this.prototype, "confirmEditTitle", [_object.action]))();
  }
  _exports.default = TitleEditor;
  ;
});